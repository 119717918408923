// General

a,
button,
.btn {
  outline: none !important;
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
}
.campagnes{
 margin-left: 0; 
 margin-right: 0; 
 margin-top: 0; 
 margin-bottom:0; 
 overflow:auto;
}
.campagne{
  margin-bottom:10px; 
}


.draggable {
  width: 100px;
  height: 100px;
  background-color: yellow;
  margin: 10px auto;
}

.inProgress {
  width: 200px;
  height: 200px;
  left: 0;
  top: 10;
  background-color: #EEEEEE;
  border-right: 2px dotted red;
}
.table-dark{
  color: white;
}


.content-media--video {
  background-color: #ddd;
  display: block;
  position: relative;
  padding: 0 0 56.25% 0;
}
.content-media--video iframe {
 position: absolute;
 bottom: 0;
 left: 0;
 width: 100%;
 height: 100%;
}

#videoMp {
  transition: width .2s ease-in-out, height .2s ease-in-out, transform .38s ease-in-out;
}
#videoMp.is-sticky {
  position: fixed;
  top: 15px;
  left: auto;
  max-width: 280px;
  max-height: 158px;
  width: 280px;
  height: 158px;
}
@media screen and (min-width: 1120px) {
   #videoMp.is-sticky {
      transform: translateX(-80%);
   }
}
@media screen and (min-width: 1300px) {
   #videoMp.is-sticky {
      transform: translateX(-115%);
   }
}


.rbc-time-column {
  border-top: none;
  padding-top: 0px;
  display: none;
}

.dropzone-content{

  border: 2px dashed #454d55;
    border-radius: 5px;
    height: 195px;
}
.dropzone-content p {
  font-weight: 600;
  text-align: center;
  margin: 2em 0;

}